import React from 'react';
import './Header.css'; // Import the CSS file for styles

const Header = () => {
  return (
    <header className="header">
      <img src="/logo.png" alt="Logo" className="logo" />
      {/* <h1 className="title">Your Website Title</h1> */}
    </header>
  );
};

export default Header;
