import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import ParticlesComponent from "./particles";

function App() {
  const [nic, setNic] = useState("");
  const [isValidNic, setIsValidNic] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageStatus, setImageStatus] = useState("");
  const [showCertificate, setShowCertificate] = useState(false);

  const handleNicChange = (e) => {
    const lowerCaseNic = e.target.value.toLowerCase(); // Convert input to lowercase
    setNic(lowerCaseNic);
  };
  

  const validateNic = (nic) => {
    const re = /^[0-9]{9}[vVxX]$|^[0-9]{12}$/; // Adjust the regex according to your NIC format
    return re.test(nic);
  };

  const downloadImage = () => {
    try {
      const link = document.createElement("a");
      link.href = imageUrl;
      link.setAttribute("download", `${nic}.png`); // The file will be downloaded as NIC_Certificate.png
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const showImage = () => {
    if (!validateNic(nic)) {
      setIsValidNic(false);
      return;
    }

    const url = `/Certificates/${nic}.png`; // Referencing the local Certificates folder
    setImageUrl(url);
    setIsValidNic(true);
    setShowCertificate(true);

    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageStatus("");
    };
    img.onerror = () => {
      setImageStatus("No certificate available.");
    };
  };

  return (
    <div className="container mt-5">
      <ParticlesComponent id="particles-background" />
      {!showCertificate && (
        <>
          <div className="row justify-content-center" id="image1" style={{ marginTop: "100px" }}>
            <div className="col-md-6 text-center">
              <img
                src="hkx.jpg"
                style={{ height: "auto", width: "100%", maxWidth: "500px",borderRadius:"10px" }}
                alt="Road to Legacy"
              />
            </div>
          </div>

          <div className="container mt-5" id="inputbox">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Get Your ideaX Certificate</h5>
                    <div className="form-group">
                      <label htmlFor="nicInput">Enter your NIC:</label>
                      <input
                          type="text"
                          className={`form-control ${!isValidNic ? "is-invalid" : ""}`}
                          id="nicInput"
                          value={nic}
                          onChange={handleNicChange}
                          placeholder="Enter NIC number"
                      />

                      {!isValidNic && (
                        <div className="invalid-feedback">Please enter a valid NIC number.</div>
                      )}
                    </div>
                    <button className="btn btn-primary" onClick={showImage}>
                      Get Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5" id="thank">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <p>
                      <br />
                     "As you continue on your journey, we encourage you to pursue research, innovation, and initiatives that make a positive impact on society. This is one of the best ways to give back to our country and contribute to building a better future. I hope the Road to Legacy event has inspired you in some small way as you embark on that path."
                      <br />
                      <br />
                      Thank you so much. Best Wishes!
                      <br />
                      <br />
                      ~ Pramuditha Vidushan - Chief Organizer, HackX 9.0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showCertificate && (
        <div className="container mt-5" id="imageSection" style={{ marginBottom: "40px" }}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <img
                  className="card-img-top"
                  id="nicImage"
                  src={imageUrl}
                  alt="NIC Certificate"
                  style={{ display: imageStatus ? "none" : "block" }}
                />
                <div className="card-body">
                  <p id="imageStatus" style={{ color: imageStatus ? "red" : "green" }}>
                    {imageStatus ? imageStatus : "Your certificate is ready!"}
                  </p>
                  {imageStatus === "" && (
                    <button className="btn btn-success btn-block" onClick={downloadImage}>
                      Download Certificate
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
